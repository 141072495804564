import { Block, Button, ButtonLink, Flex, IconFA, Input, InputType, IntrinsicProps, Modal, ModalCard, Slot, createComponent, useNotifications } from 'react-commons';
import { useRouter } from 'next/router';
import { faFacebook, faPinterest, faTwitter } from '@fortawesome/free-brands-svg-icons';

import style from './index.module.scss';

export interface ShareModalProps extends IntrinsicProps {
  gameMediaUrl: string
  gameTitle: string
}

export default createComponent('ShareModal', { style }, ({}, props: ShareModalProps) => {
  const router = useRouter();
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });

  const utmTags = 'utm_source=shockwave.com&utm_medium=social&utm_campaign=game_share&utm_content=copy_link';
  const gameUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${router.locale ?? ''}${router.asPath}${router.asPath.includes('?') ? '&' : '?'}${utmTags}`;

  const copyUrl = () => {
    navigator.clipboard.writeText(gameUrl);
    notify('Link copied successfully!', 'success');
  };

  return (
    <Modal className='ShareGameModal' name={'shareGame'}>
      <ModalCard>
        <Slot name='title'>Share {props.gameTitle}</Slot>

        <Flex directionColumn alignCenter>

          <Block>
            <Flex className='socialLinks' alignCenter justifyCenter>
              <ButtonLink large target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${gameUrl}`}>
                <IconFA large icon={faFacebook} />
              </ButtonLink>

              <ButtonLink large target='_blank' href={`https://twitter.com/intent/tweet?text=Check%20out%20${gameUrl}%20on%20Shockwave.com`}>
                <IconFA large icon={faTwitter} />
              </ButtonLink>

              <ButtonLink large target='_blank' href={`https://pinterest.com/pin/create/button/?url=${gameUrl}&media=${props.gameMediaUrl}`}>
                <IconFA large icon={faPinterest} />
              </ButtonLink>
            </Flex>
          </Block>

          <Block>
            <Flex alignCenter>
              <Input type={InputType.URL} disabled value={gameUrl} />
              <Button className='copyButton' rounded secondary onClick={copyUrl}>Copy URL</Button>
            </Flex>
          </Block>
        </Flex>
      </ModalCard>
    </Modal>
  );
});
